import { AvaNetwork } from '@/js/AvaNetwork'

export const TestnetConfig = new AvaNetwork(
    'Testnet',
    'https://rpc-testnet.lamina1.com:443',
    7649,
    '',
    '',
    false
)

export const LocalConfig = new AvaNetwork('Local', 'http://localhost:19650', 43112, '', '', false)
